:root {
  --nav-height-desktop: 76px;
  --nav-height-mobile: 56px;
}

.nav-link-text {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid transparent;
  box-sizing: border-box;
  padding-right: 25px;
}

.nav-link-text:hover,
.nav-link-text.active {
  text-decoration: underline;
}

.navigation-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  display: flex;
  justify-content: flex-start;
  width: 100vw;
  background-color: var(--primary-nav);
  height: var(--nav-height-desktop);
  color: var(--tertiary);
}

.nav-item.animate {
  transition: transform 0.3s ease;
}

.nav-item.animate:hover {
  transform: translateY(-3px);
}

.nav-item {
  display: flex;
  align-items: center;
  padding: 0 10px;
  height: 100%;
}

.nav-item > * {
  height: inherit;
}

.nav-item a img {
  height: 100%;
  box-sizing: border-box;
  padding: 20px 0px;
  width: auto;
}

.nav-logo {
  object-fit: scale-down;
}

@media (min-width: 1360px) {
  .navigation-container {
    max-width: 1360px;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media (max-width: 768px) {
  .navigation-container {
    height: var(--nav-height-mobile);
  }
}

.invert {
  filter: invert(1);
}

/** css for nav menu */
.site-nav {
  position: absolute;
  top: 5px;
  right: 10px;
  border-radius: 10px;
  border: 1px solid grey;
}

.nav-trigger {
  display: none;
}

.menu-icon {
  display: none;
}

.site-nav label[for="nav-trigger"] {
  cursor: pointer;
}

.menu-icon {
  display: block;
  float: right;
  padding: 10px;
  text-align: center;
}

input ~ .trigger {
  clear: both;
  border-radius: 10px;
  background-color: var(--primary-nav);
  overflow: hidden;
  max-width: 0;
  max-height: 0;
  transition: max-width 0.3s ease-in-out, max-height 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

input:checked ~ .trigger {
  max-width: 250px;
  max-height: 250px;
}

@media (max-width: 767px) {
  .nav-item:last-child {
    padding-bottom: 10px;
  }
}

@media (min-width: 768px) {
  .site-nav > label {
    display: none;
  }

  .site-nav {
    position: unset;
    border: none;
  }

  input ~ .trigger {
    display: flex;
    flex-direction: row;
    max-width: unset;
    max-height: unset;
    height: 100%;
  }

  input:checked ~ .trigger {
    display: flex;
    flex-direction: row;
  }

  /* Add projects dropdown menu for desktop */
  .nav-item {
    justify-content: center;
  }

  .projects:hover .sub-menu {
    height: auto;
    max-height: 300px;
    opacity: 1;
  }

  .sub-menu li {
    padding-left: 20px;
    padding-right: 20px;
  }

  .sub-menu li {
    white-space: nowrap;
  }

  .sub-menu li:last-child {
    padding-bottom: 10px;
  }
}

.sub-menu {
  position: absolute;
  top: 99%;
  max-height: 0;
  overflow: hidden;
  transition: max-height 250ms linear, opacity 250ms linear;
  background-color: white;
  /* border-left: 1px solid grey;
  border-bottom: 1px solid grey;
  border-right: 1px solid grey; */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  clip-path: inset(1px -10px -10px -10px);
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  opacity: 0;
}
