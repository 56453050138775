@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary: black;
  --secondary: #fff;
  --tertiary: #1877f2;

  --primary-nav: #fff;

  --primary-alt: #fff;
  --secondary-alt: #414541;


  --callout: rgb(255, 255, 102);
  --callout-dark: #101010;

  --menu-collapsed-width: 70px;
  --menu-expanded-width: 200px;
  --card-gap: 60px;

  --font-size-xlarge: 24px;
  --font-size-large: 22px;
  --font-size-medium: 20px;
  --font-size-small: 18px;
  --font-size-xsmall: 16px;
  --font-size-xxsmall: 14px;
}

#root {
  display: flex;
  flex-direction: column;
  height: -webkit-fill-available;
}

main {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--primary);
}

html, body, main {
  height: -webkit-fill-available;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Open Sans', sans-serif;
}

html {
  background-color: var(--secondary);
  font-size: var(--font-size-xxsmall);
  overflow-y: scroll;
  transition: background-color 1s ease;
}

a {
  /* Remove underline and color */
  text-decoration: none;
  color: inherit;

  /* Reset margin and padding */
  margin: 0;
  padding: 0;

  /* Set cursor to pointer */
  cursor: pointer;

  /* Set font weight to normal */
  font-weight: normal;

  /* Remove outline when focused */
  outline: none;
}

input, button, textarea, select {
  font: inherit;
}

ul {
  list-style-type: none;
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 0;
}

.shadow {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,.2), 0 6px 20px 0 rgba(0,0,0,.19);
}

.padding {
  flex: 1;
}

a:hover {
  text-decoration: underline;
}

a {
  text-decoration: none;
}

p {
  padding-bottom: 20px;
}

@media (min-width: 600px) {
  html {
    font-size: var(--font-size-xsmall);
  }
}

@media (min-width: 768px) {
  html {
    font-size: var(--font-size-small);
  }
}

@media (min-width: 1280px) {
  html {
    font-size: var(--font-size-medium);
  }
}


@media (min-width: 1025px) {
  html {
    font-size: var(--font-size-large);
  }
}

@media (min-width: 1360px) {
  html {
    font-size: var(--font-size-xlarge);
  }
}

