.grid-container {
  width: 100%;
  aspect-ratio: 1;
  display: flex;
  flex-direction: column;
  gap: var(--card-gap);
}

.grid-text {
  flex-basis: 60%;
  flex-grow: 1;
  flex-shrink: 1;
  box-sizing: border-box;
  padding: 20px 40px;
  display: flex;
  flex-direction: column;
  font-family: 'Open Sans', sans-serif;
}

.grid-card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.grid-card:last-child {
  padding-bottom: var(--card-gap);
}

.grid-title {
  color: var(--tertiary);
  font-weight: 600;
  font-size: 2rem;
  white-space: nowrap;
  cursor: pointer;
  transition: all .1s ease-in;
}

.grid-summary {
  padding-top: 1.5rem;
}

.grid-title:hover {
  text-decoration: underline;
}

.grid-image-wrapper {
  display: flex;
  justify-content: center;
}

.grid-image {
  flex-basis: 40%;
  flex-grow: 1;
  flex-shrink: 1;
  max-width: 415px;
  object-fit: contain;
}

.grid-button-container {
  display: flex;
  margin-top: auto;
}

.grid-button-container a {
  margin-right: 50px;
}

.grid-button-container a:not(:last-child) {
  padding-bottom: 10px;
}

.grid-top-wrapper {
  display: flex;
  justify-content: space-between;
}

.grid-right {
  display: none;
}

.grid-left {
  box-sizing: border-box;
  width: 46%;
  display: flex;
  flex-direction: column;
  min-width: 200px;
}

.grid-left>* {
  box-sizing: border-box;
}

button.grid-button {
  width: 127px;
  background-color: var(--tertiary);
  color: var(--primary-nav);
  border-radius: 5px;
  border: 1px solid var(--tertiary);
  cursor: pointer;
  padding: 10px;
  transition: all 0.3s ease;
}

button.grid-button:hover {
  color: var(--tertiary);
  background-color: var(--primary-nav);
}

.tool-logo {
  object-fit: cover;
  width: 2em;
  height: 2em;
}

li.tool-row {
  display: flex;
  align-items: center;
  width: auto;
  justify-content: space-between;
}

.grid-tools>ul>* {
  margin-bottom: 5px;
}

@media (max-width: 300px) {
  .grid-image-wrapper {
    display: none;
  }
}

@media (max-width: 600px) {
  .grid-tools {
    width: 120px;
  }
}

@media (min-width: 600px) {
  .grid-tools {
    width: 140px;
  }
}

@media (min-width: 1024px) {
  .grid-tools {
    width: 160px;
  }
}

@media (min-width: 1360px) {
  .grid-tools {
    width: 180px;
  }
}

@media (max-width: 768px) {
  .grid-image-wrapper {
    display: none;
  }

  .grid-right {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  img.grid-image {
    max-height: 300px;
    max-width: 138px;
    flex: 0;
    object-fit: contain;
  }

  .grid-tools>ul {
    padding-left: 20px;
  }

  .grid-title {
    font-size: 1.7rem;
    white-space: unset;
  }

  .grid-button-container {
    flex-direction: column;
    padding-left: 20px;
    flex: 1;
    justify-content: flex-end;
  }

  .grid-button-container a {
    margin-right: 0px;
    width: 89px;
    height: 35px;
  }

  button.grid-button {
    width: 100%;
    height: 100%;
    font-size: 0.8rem;
    padding: 5px;
  }

  .grid-text {
    padding: 20px 20px;
  }

  .grid-left {
    min-width: 150px;
  }
}

/* keyframe animation */

.grid-card {
  opacity: 0;
  position: relative;
  margin-left: 0px;
  animation: fade-in 1s ease forwards;
  padding-bottom: 5px;
}

@keyframes fade-in {
  from {
    opacity: 0;
    margin-left: 550px;
  }

  to {
    opacity: 1;
    margin-left: 0;
  }
}

.grid-card:nth-child(2) {
  animation-delay: 0.5s;
}

.grid-card:nth-child(3) {
  animation-delay: 1s;
}

.grid-card:nth-child(4) {
  animation-delay: 1.5s;
}

.grid-card:nth-child(5) {
  animation-delay: 2s;
}

.grid-card:nth-child(6) {
  animation-delay: 2.5s;
}

.grid-card:nth-child(7) {
  animation-delay: 3s;
}
