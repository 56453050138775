.logo-container {
  height: 100%;
  cursor: pointer;
}

.rustacean {
  position: relative;
  height: 100%;
  z-index: 1;
}

.react-logo {
  position: absolute;
  top: 0;
  left: -20px;
  height: 60%;
  width: auto;
  z-index: 2;
  animation: rotation 10s infinite linear;
}

.ts-logo {
  position: relative;
  top: -5px;
  left: -15px;
  height: 60%;
  z-index: 0;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 1396px) {
  .rustacean {
    padding-left: 10px;
  }
  .react-logo {
    left: -13px;
  }
}

@media (max-width: 768px) {
  .react-logo {
    left: -7px;
  }
}
