.one-off {
  min-height: 300px;
  max-height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.one-off > .grid-card {
  margin-top: calc(82px + var(--card-gap));
  height: 100%;
  padding-bottom: 0;
}
