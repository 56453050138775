.about {
  padding-top: 20px;
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5%;
}

.about a {
  color: var(--tertiary);
}

.about-top {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.profile-pic {
  display: flex;
  flex: 60%;
  justify-content: center;
}

.profile-pic > img {
  border-radius: 50%;
  object-fit: contain;
  max-width: 400px;
  aspect-ratio: 1;
}

.introduction {
  box-sizing: border-box;
  padding-top: 40px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.introduction > p {
  max-width: 80%;
}

.skills {
  flex: 40%;
  display: flex;
  align-items: center;
}

.skills > ul {
  margin: 0;
}

.skills > ul > li {
  opacity: 0;
  position: relative;
  left: 30px;
  animation: fade-in 1s ease forwards;
  padding-bottom: 5px;
}

@keyframes fade-in {
  from {
    opacity: 0;
    left: 50px;
  }
  to {
    opacity: 1;
    left: 0;
  }
}

.skills > ul > li:nth-child(2) {
  animation-delay: 0.2s;
}

.skills > ul > li:nth-child(3) {
  animation-delay: 0.4s;
}

.skills > ul > li:nth-child(4) {
  animation-delay: 0.6s;
}

.skills > ul > li:nth-child(5) {
  animation-delay: 0.8s;
}

.skills > ul > li:nth-child(6) {
  animation-delay: 1.0s;
}

.skills > ul > li:nth-child(7) {
  animation-delay: 1.2s;
}

@media (max-width: 768px) {
  .introduction > p {
    max-width: 100%;
    padding-right: 20px;
    padding-left: 20px;
  }
  .profile-pic > img {
    max-width: 80%;
  }
}

@media (max-height: 768px) {
  .about {
    justify-content: unset;
  }
}

.navigation-height {
  padding-top: var(--nav-height-desktop);
}

@media (max-width: 768px) {
  .navigation-height {
    padding-top: var(--nav-height-mobile);
  }
}

.col-centered {
  display: flex;
  flex-direction: column;
  height: 100%;
}
